<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('menus.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 dark:bg-theme-dark-2">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="overflow-x-auto">
        <table class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap text-center">#</th>
              <th class="whitespace-nowrap text-center">
                {{ i18n('menus.table.title') }}
              </th>
              <th class="whitespace-nowrap text-center">
                {{ i18n('menus.table.menu') }}
              </th>
              <th class="whitespace-nowrap text-center">
                {{ i18n('menus.table.user') }}
              </th>
              <th class="whitespace-nowrap text-center">
                {{ i18n('menus.table.createdAt') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40 text-center">
                <div class="text-black text-xs whitespace-nowarp mt-0 5">
                  {{ index + 1 }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterLocalization(row, 'name') }}
                </div>
              </td>
              <td class="flex items-center justify-center">
                <div class="font-medium whitespace-nowrap text-gray-600">
                  <div
                    class="
                      flex-none
                      rounded-md
                      overflow-hidden
                      w-64
                      flex
                      items-center
                      gap-4
                    "
                  >
                    <!-- <pdf :src="row.file" class="pdf-thumbnail"></pdf> -->
                    <embed
                      width="191"
                      height="207"
                      name="plugin"
                      :src="row.file"
                      type="application/pdf"
                    />
                    <div class="flex flex-col">
                      <a
                        :href="row.file"
                        target="_blank"
                        class="text-theme-31 flex items-center w-12 h-12"
                        ><EyeIcon class="w-5 h-5 mr-2 ml-2"
                      /></a>
                      <div class="text-theme-31 flex items-center w-12 h-12">
                        <CopyIcon
                          class="cursor-pointer w-5 h-5 mr-2 ml-2"
                          @click="copyFileURL(row.file)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row.owner, 'fullName') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterDate(row, 'createdAt') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        :page="currentPage"
        :pageSize="pagination.limit"
        :showSizeChanger="true"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      ></Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <!-- <PDFViewer
      initialDoc="https://firebasestorage.googleapis.com/v0/b/nfc-menu-system.appspot.com/o/meuns%2FzD0dOGBJJwYG4v3uRWT3a9AppEC3%2FMenu-1.pdf?alt=media&token=0165a8db-3a2c-4a28-bbff-610c979e755b"
    /> -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
import Message from '@/shared/message/toastify'
// import PDFViewer from './menu-viewer.vue'
// import pdf from 'vue3-pdf'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.menu')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.menu')
    }
  },
  setup() {
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectAll,
      tableLimit,
      tableRef
    }
  },
  data() {
    return {
      filterInput: {},
      filterFields: [
        // { label: this.i18n('iam.filter.username'), value: 'owner' },
        {
          label: this.i18n('iam.filter.title'),
          value: 'name'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'menus/list/rows',
      pagination: 'menus/list/pagination',
      loading: 'menus/list/loading'
    }),
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) =>
        index + this.pagination.limit * (this.currentPage - 1) + 1
    }
  },
  // components: {
  //   PDFViewer
  //   // pdf
  // },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'menus/list/doFetch',
      doFetchNextPage: 'menus/list/doFetchNextPage',
      doFetchPreviousPage: 'menus/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'menus/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'menus/list/doChangePaginationPageSize'
    }),
    async doFilter(filter) {
      if (!filter || !filter.value) return
      const key = filter.key === 'owner' ? 'fullName' : this.isRTL ? 'ar' : 'en'
      this.filterInput = {
        [filter.key]: {
          [key]: filter.value
        }
      }
      // console.log('{{filterInput}}', this.filterInput)
      await this.doFetchFirstPage()
    },
    async doResetFilter() {
      this.filterInput = {}
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        pagination: {
          firstPage: true,
          limit: 12,
          action: 'next',
          sortBy: 'desc'
        },
        filter: this.filterInput,
        orderBy: 'createdAt'
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    copyFileURL(url) {
      navigator.clipboard.writeText(url)
      Message.success(this.i18n('common.clipboard'))
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    }
  }
})
</script>

<style lang="scss">
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.dark {
  .table-report:not(.table-report--bordered):not(.table-report--tabulator) {
    td,
    th {
      background: none !important;
    }
  }
}

.delete-icon {
  position: absolute;
  width: 80px;
  cursor: pointer;
  margin-top: 200px;
  margin-left: 10px;
  @media only screen and (max-width: 1440px) {
    width: 80px;
    margin-top: 120px;
  }
  @media only screen and (max-width: 1024px) {
    width: 80px;
    margin-top: 80px;
  }
  @media only screen and (max-width: 768px) {
    width: 80px;
    margin-top: 120px;
  }
  @media only screen and (max-width: 425px) {
    width: 80px;
    margin-top: 60px;
  }
  @media only screen and (max-width: 375px) {
    width: 60px;
    margin-top: 60px;
  }
  @media only screen and (max-width: 320px) {
    width: 60px;
    margin-top: 40px;
  }
  @media only screen and (max-width: 300px) {
    width: 60px;
    margin-top: 20px;
  }
}
[dir='rtl'] .delete-icon {
  margin-left: unset !important;
  margin-right: 10px !important;
}

.pdf-thumbnail {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 12rem;
  overflow: hidden;

  & canvas {
    object-fit: scale-down;
  }
}
</style>
